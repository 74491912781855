import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Box, Flex, Text, useTheme } from '@chakra-ui/react'

import {
	AnalyticsWrapper,
	Layout,
	analyticsSend
} from '@perchinteractiveinc/react-template-lb'

import { AllProductsQuery, i18nProducts, Product } from 'interfaces'
import {
	PRODUCT_PAGE_LABELS,
	PRODUCT_ICONS_LABELS,
	getProductsLocale,
	noNbspString
} from 'utils'
import { LangContext } from 'context'
import {
	ScoreReview,
	TextCarousel,
	BackButton,
	LangTabs,
	ProductPageCarousel,
	ProductModal,
	StarLabel,
	SensingWrapper
} from 'components'
import { ImagePlaceholder, LineLongDashed } from 'assets'

import howToVideoen from 'assets/videos/how-to-scroll-en.webm'
import reviewsVideoen from 'assets/videos/reviews-scroll-en.webm'
import howToVideohe from 'assets/videos/how-to-scroll-he.webm'
import reviewsVideohe from 'assets/videos/reviews-scroll-he.webm'

import * as styles from './product.module.scss'
import '../styles/global.css'

export default function ProductTemplate({ data }: { data: AllProductsQuery }) {
	// const planogram = getImage(
	// 	data.planogramImage.shelf_image.localFile.childImageSharp.gatsbyImageData
	// )
	const product: i18nProducts = getProductsLocale(data)

	const { lang } = useContext(LangContext)
	const [currentProduct, setCurrentProduct] = useState<Product>()
	const engProducts = useMemo(() => {
		const list = data.allStrapiProduct.nodes.filter(p => p.locale === 'en')
		return list.reduce<{
			[key: string]: Partial<Product>
		}>((products, prod) => {
			if (!prod.product_uid) {
				return products
			}
			products[prod.product_uid] = prod
			return products
		}, {})
	}, [data.allStrapiProduct])

	// const currentEngProduct = useMemo(() => {
	// 	const prod = engProducts[currentProduct.product_uid]
	// 	if (prod) {
	// 		return prod
	// 	}
	// 	return currentProduct
	// }, [engProducts, currentProduct])

	const onRecommendedModalConfirm = useCallback(
		(product_uid: string) => {
			const prod = engProducts[product_uid]
			if (prod) {
				analyticsSend({
					type: 'track',
					button: 'Learn more',
					product: prod.name
				})
			}
		},
		[engProducts]
	)

	const onRecommendedModalClose = useCallback(
		(product_uid: string) => {
			const prod = engProducts[product_uid]
			if (prod) {
				analyticsSend({
					type: 'track',
					button: 'Close recommended modal',
					product: prod.name
				})
			}
		},
		[engProducts]
	)

	const onCarouselChange = useCallback(
		(i: number, uid?: string) => {
			if (uid) {
				const prod = engProducts[uid]
				if (prod) {
					analyticsSend({
						type: 'track',
						button: 'Product detail - carousel',
						product: prod.name
					})
				}
			}
		},
		[engProducts]
	)

	useEffect(() => {
		setCurrentProduct(product[lang][0])
	}, [lang, setCurrentProduct, product])

	const theme = useTheme()

	const isVideo = (url: string) => url.endsWith('.mp4')

	const emptyHowToStar = useMemo(
		() => currentProduct && currentProduct.how_to.length <= 1,
		[currentProduct]
	)

	if (!currentProduct) {
		return null
	}
	return (
		<SensingWrapper enableTimer products={Object.values(engProducts)}>
			<Layout>
				<div className={lang === 'he' ? 'hebrew' : ''}>
					<Box className={styles.productPage}>
						<Box className={styles.options}>
							{currentProduct.collection?.products && (
								<ProductPageCarousel
									itemsToShow={5}
									items={currentProduct.collection?.products}
									itemsHeight='138px'
									itemsWidth='100px'
									currentProductId={currentProduct.product_uid}
									onCarouselChange={onCarouselChange}
								/>
							)}
						</Box>
						<Box
							className={styles.header}
							bgColor={
								currentProduct.collection &&
								currentProduct.collection.collection_color
							}
						>
							<Box w='164px'>
								<BackButton zIndex={1} />
							</Box>
							<Text variant='title'>{noNbspString(currentProduct.name)}</Text>
							<div className={styles.tabsContainer}>
								<LangTabs />
							</div>
						</Box>

						<Box
							className={`${styles.mainInfo} ${
								lang === 'he' ? styles.reverse : ''
							}`}
						>
							<Box
								className={styles.picture}
								bgColor={currentProduct.parsedImage && 'transparent'}
							>
								{currentProduct.parsedImage ? (
									<GatsbyImage
										image={currentProduct.parsedImage}
										alt='Product image'
									/>
								) : (
									<ImagePlaceholder />
								)}
							</Box>

							<Box className={styles.productInfo}>
								<Box>
									<Box
										className={`${styles.productInfoTitles} ${
											lang === 'he' ? styles.justifyEnd : ''
										}`}
									>
										<Box className={styles.leftTitle}>
											<Text variant='product'>{currentProduct.headline}</Text>
										</Box>
									</Box>

									<Box className={styles.productDescriptions} mb='30px'>
										<Text
											variant='paragraphMd'
											fontWeight={600}
											className={styles.textDescriptionColumn}
											fontSize='18px'
											lineHeight='23px'
										>
											{currentProduct.description?.data?.description}
										</Text>
									</Box>
								</Box>

								<Flex
									mb='40px'
									justifyContent={lang === 'he' ? 'flex-end' : 'flex-start'}
								>
									<Flex gap='15px'>
										{currentProduct.parsedIcons && (
											<>
												{currentProduct.parsedIcons.map((icon, idx) => (
													<Flex direction='column' w='77px' key={idx}>
														{icon?.image && (
															<Box justifyContent='center' display='flex'>
																<GatsbyImage
																	image={icon.image}
																	alt='Benefit icon'
																/>
															</Box>
														)}
														<Text
															variant='paragraphSm'
															fontSize='15px'
															mt='7px'
															textAlign='center'
														>
															{PRODUCT_ICONS_LABELS[lang][icon.caption]}
														</Text>
													</Flex>
												))}
											</>
										)}
									</Flex>
								</Flex>
							</Box>
						</Box>
						<Box height='52px'>
							<video
								className={`${lang === 'he' ? styles.hide : ''}`}
								autoPlay
								loop
								muted
							>
								<source src={reviewsVideoen} type='video/webm' />
							</video>
							<video
								className={`${lang === 'en' ? styles.hide : ''}`}
								autoPlay
								loop
								muted
							>
								<source src={reviewsVideohe} type='video/webm' />
							</video>
						</Box>
						<Box
							className={`${styles.score} ${
								lang === 'he' ? styles.reverse : ''
							}`}
						>
							<Box width='490px'>
								<TextCarousel className='reviews-carousel'>
									{currentProduct?.reviews?.map(
										({ title, description, stars }, idx: number) => (
											<Box
												key={idx}
												h='260px'
												display='flex'
												flexDirection='column'
												justifyContent='center'
											>
												<Text
													variant='subtitle'
													lineHeight='30px'
													fontSize='30px'
												>
													{title}
												</Text>
												<ScoreReview score={stars} />
												<Text
													lineHeight='28px'
													fontSize='21px'
													variant='paragraphMd'
													h='100%'
												>
													{description}
												</Text>
											</Box>
										)
									)}
								</TextCarousel>
							</Box>
							<Box ml='91px' mr='21px'>
								<LineLongDashed />
							</Box>

							<Box>
								<Flex direction='row'>
									<Text
										className={lang === 'he' ? styles.hide : undefined}
										fontSize='40'
										color='orange'
									>
										+
									</Text>
									<Text variant='subtitle' mb='41px'>
										{PRODUCT_PAGE_LABELS[lang]?.WORKS_WELL_WITH}
									</Text>
									<Text
										className={lang === 'en' ? styles.hide : undefined}
										fontSize='40'
										color='orange'
									>
										+
									</Text>
								</Flex>
								<Flex gap='20px' width='360px'>
									{currentProduct.ymal_products?.map((product, idx) => {
										const image = getImage(
											product.image.localFile.childImageSharp.gatsbyImageData
										)
										{
											return image ? (
												<Box className={styles.productCard} key={idx}>
													<ProductModal
														lang={lang}
														name={product.name}
														uid={product.product_uid}
														onConfirm={onRecommendedModalConfirm}
														onClose={onRecommendedModalClose}
														button={
															<AnalyticsWrapper
																button='Product Detail - Recommended'
																product={engProducts[product.product_uid].name}
															>
																<GatsbyImage image={image} alt='YMAL image' />
															</AnalyticsWrapper>
														}
														headline={product.headline}
													/>
												</Box>
											) : (
												<></>
											)
										}
									})}
								</Flex>
							</Box>
						</Box>
						<Box height='52px'>
							<video
								className={`${lang === 'he' ? styles.hide : ''}`}
								autoPlay
								loop
								muted
							>
								<source src={howToVideoen} type='video/webm' />
							</video>
							<video
								className={`${lang === 'en' ? styles.hide : ''}`}
								autoPlay
								loop
								muted
							>
								<source src={howToVideohe} type='video/webm' />
							</video>
						</Box>
						<Box
							className={`${styles.howTo} ${
								lang === 'he' ? styles.reverse : ''
							}`}
						>
							<Box className={styles.howToVideo}>
								{currentProduct.video &&
									(isVideo(currentProduct.video.url) ? (
										<video autoPlay muted loop>
											<source src={currentProduct.video.url} type='video/mp4' />
										</video>
									) : (
										<img
											src={currentProduct.video.url}
											alt='video image placeholder'
										/>
									))}
							</Box>
							<Box className={styles.howToContent}>
								<Flex direction='row' alignItems={'center'}>
									<Text
										className={lang === 'he' ? styles.hide : undefined}
										fontSize='30px'
										color='orange'
									>
										+
									</Text>
									<Text lineHeight='30px' fontSize='30px' variant='subtitle'>
										{PRODUCT_PAGE_LABELS[lang]?.HOW_TO}
									</Text>
									<Text
										className={lang === 'en' ? styles.hide : undefined}
										fontSize='30px'
										color='orange'
									>
										+
									</Text>
								</Flex>
								<TextCarousel className='how-to-carousel'>
									{currentProduct.how_to?.map(
										({ step_number, description }, idx: number) => (
											<Box key={idx} height='248px'>
												{!emptyHowToStar && (
													<StarLabel
														height='90px'
														width='90px'
														fill={theme.colors.orange}
													>
														<Text variant='subtitle'>{step_number}</Text>
													</StarLabel>
												)}
												<Box width={lang === 'en' ? 350 : 300}>
													<Text
														lineHeight='28px'
														fontSize='20px'
														variant='paragraphMd'
														width={326}
													>
														{description}
													</Text>
												</Box>
											</Box>
										)
									)}
								</TextCarousel>
							</Box>
						</Box>

						<Box
							className={styles.footer}
							bgColor={
								currentProduct.collection &&
								currentProduct.collection.collection_color
							}
						></Box>
					</Box>
				</div>
			</Layout>
		</SensingWrapper>
	)
}

export const query = graphql`
	query ($product_uid: String!) {
		products: allStrapiProduct(filter: { product_uid: { eq: $product_uid } }) {
			nodes {
				sensing_id
				reviews {
					description
					stars
					title
				}
				name
				locale
				headline
				product_uid
				video {
					url
				}
				image {
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
				how_to {
					step_number
					description
				}
				product_icons {
					caption
					localFile {
						childImageSharp {
							gatsbyImageData
						}
					}
				}
				collection {
					products {
						name
						headline
						product_uid
						image {
							localFile {
								childImageSharp {
									gatsbyImageData
								}
							}
						}
					}
					collection_color
				}
				description {
					data {
						description
					}
				}
				ymal_products: products {
					name
					headline
					product_uid
					image {
						localFile {
							childImageSharp {
								gatsbyImageData
							}
						}
					}
				}
			}
		}
		allStrapiProduct {
			nodes {
				sensing_id
				product_uid
				name
				locale
			}
		}
		planogramImage: strapiPlanogram {
			shelf_image {
				localFile {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	}
`
