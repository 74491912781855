export const LineLongDashed = () => (
	<svg
		width='1'
		height='284'
		viewBox='0 0 1 284'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<line
			x1='0.5'
			y1='0.5'
			x2='0.500012'
			y2='283.5'
			stroke='#FF6B26'
			strokeLinecap='square'
			strokeDasharray='9 6'
		/>
	</svg>
)
