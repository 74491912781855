// extracted by mini-css-extract-plugin
export var divider = "product-module--divider--hnE1+";
export var footer = "product-module--footer--LCZQ5";
export var header = "product-module--header--bSX1U";
export var hide = "product-module--hide--dBG6B";
export var howTo = "product-module--how-to--L0AbF";
export var howToContent = "product-module--how-to-content--c6CVh";
export var howToDescription = "product-module--how-to-description--I+Q0y";
export var howToTitle = "product-module--how-to-title--jR4yV";
export var howToVideo = "product-module--how-to-video--5haQ2";
export var justifyEnd = "product-module--justify-end--312ve";
export var leftTitle = "product-module--left-title--GKFpI";
export var locateProductContainer = "product-module--locate-product-container--IWtJ2";
export var mainInfo = "product-module--main-info--bKlsj";
export var options = "product-module--options--krXne";
export var orangeCircle = "product-module--orange-circle--jmsAW";
export var picture = "product-module--picture--b0w5h";
export var productCard = "product-module--product-card--GZh1Y";
export var productDescriptions = "product-module--product-descriptions--aEaUA";
export var productInfo = "product-module--product-info--1UW3V";
export var productInfoTitles = "product-module--product-info-titles--y-B5v";
export var productPage = "product-module--product-page--jsc12";
export var reverse = "product-module--reverse--NJfOj";
export var rightTitle = "product-module--right-title--Z2PqT";
export var score = "product-module--score--fHcP2";
export var tabsContainer = "product-module--tabs-container--Tzbqa";
export var textDescriptionColumn = "product-module--text-description-column--2qv+E";